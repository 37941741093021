<template>
	<div class="partnerList_wrap clearfix">
    <div class="listOne clearfix" v-if="name === 'all'">
      <div id="logo" class="clearfix partnerListOne" v-for="(item, index) in dataInfo" :key="item.partner_id">
        <div class="listOne_img clearfix" :class="{'active': hidden == index}" @click="detail(index)" :key="index">
          <a :title="item.meta_keyword" rel="nofollow noopener noreferrer">
            <img :src="item.partner_image" :alt="item.meta_keyword" :title="item.meta_keyword">
          </a>
          <div class="img-hidden clearfix" :class="{'activeSub': hidden == index}"></div>
        </div>
        <div :id="index"></div>
        <div class="listOne-Info clearfix" :class="{'activeSub': hidden == index}">
          <h3>{{item.partner_name}}</h3>
          <div class="Info" v-if="item.partner_supported_analytic===''">
            <div class="place">
              <p>{{LP.lang_tel}} : {{item.partner_tel}}</p>
              <p v-show="item.partner_email != ''">{{LP.lang_email}} : {{item.partner_email}}</p>
              <p v-show="item.partner_url != ''">{{LP.lang_url}} : <a :href="item.partner_url" target="_blank" rel="nofollow noopener noreferrer">{{item.partner_url}}</a></p>
            </div>
            <div class="place_right">
              <p v-show="item.partner_text != ''">{{LP.lang_supported_products}}</p>
              <p v-html="item.partner_text"></p>
            </div>
          </div>
          <div class="Info" v-else>
            <div class="place">
              <p>{{LP.lang_tel}} : {{item.partner_tel}}</p>
              <p v-show="item.partner_email != ''">{{LP.lang_email}} : {{item.partner_email}}</p>
              <p v-show="item.partner_url != ''">{{LP.lang_url}} : <a :href="item.partner_url" target="_blank" rel="nofollow noopener noreferrer">{{item.partner_url}}</a></p>
            </div>
            <div class="place_right">
              <p>{{LP.lang_supported_products}}</p>
              <p v-html="item.partner_text"></p>
              <p style="margin-top:10px">{{LP.lang_supported_analytic}}</p>
              <p>{{item.partner_supported_analytic}}</p>
            </div>
          </div>
          <div class="list_right_content" v-if="item.partner_message != ''">
            <content v-html="item.partner_message"></content>
          </div>
        </div>
      </div>
      <div class="partnerListOne placeholder-item" v-for="(item, i) in placeholderList" :key="'placeholder'+i"></div>
    </div>
    <div v-else>
      <div class="clearfix partnerList" v-for="(item) in dataInfo" :key="item.partner_id">
        <div class="list_img pull-left">
          <a :href="item.partner_url" target="_blank" :title="item.meta_keyword" rel="nofollow noopener noreferrer">
            <img :src="item.partner_image" :alt="item.meta_keyword" :title="item.meta_keyword">
          </a>
          <div>
            <h3>{{item.partner_name}}</h3>
            <p>{{LP.lang_tel}} : {{item.partner_tel}}</p>
            <p v-show="item.partner_email != ''">{{LP.lang_email}} : {{item.partner_email}}</p>
            <p v-show="item.partner_url != ''">{{LP.lang_url}} : <a :href="item.partner_url" target="_blank" rel="nofollow noopener noreferrer">{{item.partner_url}}</a></p>
          </div>
        </div>
        <div class="list_right clearfix">
          <div   class="intro clearfix">
            <div v-if="item.partner_supported_analytic===''">
              <div class="place_right" v-show="item.partner_text != ''">
                <p>{{LP.lang_supported_products}}</p>
                <p v-html="item.partner_text"></p>
              </div>
            </div>
            <div v-else>
              <div class="place_right">
                <p>{{LP.lang_supported_products}}</p>
                <p v-html="item.partner_text"></p>
                <p style="margin-top:10px">{{LP.lang_supported_analytic}}</p>
                <p>{{item.partner_supported_analytic}}</p>
              </div>
            </div>

            <div class="list_right_content" >
              <content v-html="item.partner_message"></content>
            </div>
          </div>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
// import pubButton from '~/components/common/pubButton'
export default {
  props: {
    dataInfo: [Object, Array],
    name: {
      type: String
    },
    path: {
      type: String
    }
  },
  data() {
    return {
      hidden: -1,
      show: false,
      placeholderList: [1,2,3,4,5,6,7,8,9,10]
    }
  },
  components: {
    // pubButton
  },
  methods: {
    detail(index) {
      this.hidden = this.hidden === index ? -1 : index;
    },
    click(e) {
      let container = document.getElementsByClassName('partnerListOne')
      let target = e.target
      let a = true
      for (let i = 0; i < container.length; i++) {
        if (container[i].contains(target)) {
          a = false
        }
      }
      if (a) {
        this.hidden = -1;
      }
    }
  },
  mounted() {
    window.addEventListener('click', e => this.click(e), true);
  }
}
</script>

<style scoped lang="less">
@import '~@/styles/common/common.less';
.partnerList {
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
  margin-bottom: 30px;
  display: flex;
  .list_img {
    width: 300px;
    a {
      display: inline-block;
      max-width: 300px;
      max-height: 200px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    > div {
      overflow: hidden;
      padding-left: 16px;
      padding-bottom: 16px;
      > h3 {
        font-size: 20px;
        font-weight: 440;
        margin-bottom: 20px;
      }
      > p {
        width: 85%;
        font-size: 14px;
        color: #666;
        word-break: break-all;
        a {
          color: #0e5fac;
        }
      }
    }
  }
  .list_right {
    padding: 13px 50px 0;
    width: calc(~"100% - 300px");
    display: table-cell;
    .list_right_content {
      margin: 14px 0;
      border: 1px dashed #dadada;
      font-size: 14px;
      content {
        display: block;
        padding: 19px;
      }
    }
    .intro {
      h3 {
        font-size: 20px;
        font-weight: 440;
        margin-bottom: 20px;
      }
      > div {
        overflow: hidden;
        &.on {
          width: 100%;
        }
        > p {
          width: 85%;
          font-size: 14px;
          color: #666;
          margin-bottom: 30px;
          overflow: hidden;
          padding-right: 5%;
          &.on {
            width: 100%;
          }
        }
        .place {
          width: 35%;
          color: #666;
          p {
            a {
              color: #0e5fac;
            }
          }
        }
        // .place_right {
        //   float: left;
        //   width: 55%;
        // }
      }
      .pub-button {
				margin-top: 20px;
				margin-bottom: 20px;
        .transition();
      }
      button {
        width: 130px;
        height: 30px;
        color: #666;
        line-height: 30px;
        display: block;
        text-align: center;
        background-color: #f5f5f5;
        border: 1px solid #ccc;
      }
    }
    .intro.on > div > p {
      width: 100%;
    }
  }
  &:hover {
    .shadow();
  }
  @media screen and (max-width: 1078px) {
    .list_right {
      .intro {
        & > div.pub-button {
          margin-bottom: 20px;
        }
        > div {
          .place_right {
            width: auto;
            margin-left: 0;
          }
          .place {
            width: 100%;
            border-right: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    .list_img {
      width: 100%;
      float: none;
      >a {
        display: inline-block;
        max-width: unset;
        max-height: unset;
        width: 100%;
      }
    }
    .list_right {
      width: 100%;
      // padding: 0.466667rem 0.466667rem 0.733333rem 0.533333rem;
      padding: 16px;
      .intro {
        & > div.pub-button {
          margin-bottom: 0px;
        }
        > div {
          width: 100%;
          float: none;
          // h3 {
          //   font-size: 0.48rem;
          // }
          p {
            // font-size: 0.426667rem;
            width: 100%;
            padding-left: 0;
            border-right: 0 none;
          }
          button {
            font-size: 0.32rem;
          }
          .place {
            // font-size: 0.426667rem;
            width: 100%;
            border-left: 0 none;
            border-top: 1px solid #ccc;
            padding-left: 0;
            padding-top: 15px;
            padding-bottom: 15px;
            // padding-top: 0.466667rem;
            // padding-bottom: 0.466667rem;
          }
        }
      }
    }
  }
}
.listOne {
  font-size: 14px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  .partnerListOne {
    width: 23%;
    max-width: 332px;
    margin-bottom: 30px;
    background: #fff;
    height: fit-content;
    .listOne_img {
      display: flex;
      width: 100%;
      height: 100%;
      max-width: 332px;
      max-height: 200px;
      overflow: hidden;
      position: relative;
      background: #fff;
      align-items: center;
      padding-bottom: 15px;
      a {
        display: inline-block;
        width: 100%;
      }
      img {
        display: block;
        margin: 20px auto;
        height: auto;
        max-height: 200px;
        width: 100%;
        max-width: 300px;
      }
    }
    .img-hidden {
      display: none;
      width: 100%;
      height: 15px;
      z-index: 23;
      position: absolute;
      bottom: 0;
      background: #fff;
    }
    .listOne-Info {
      display: none;
      width: 100%;
      background: #fff;
      box-shadow: 0px 0px 10px 3px #dadada;
      height: fit-content;
      position: absolute;
      left: 0;
      z-index: 22;
      padding: 25px 22px;
      h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0px;
        color: #323333;
        margin-bottom: 16px;
      }
      .Info {
        display: flex;
        justify-content: space-between;
        .place {
          flex: 1;
          min-height: 60px;
          padding-right: 10px;
          p {
            line-height: 24px;
            font-size: 14px;
            white-space: normal;
            word-break: break-word;
          }
        }
        .place_right {
          width: 66%;
          padding-left: 80px;
          border-left: 1px solid #f8f8f8;
          white-space: normal;
          word-break: break-word;
          p {
            line-height: 24px;
            font-size: 14px;
          }
        }
      }
      .list_right_content {
        width: 100%;
        margin-top: 25px;
        border: 1px dashed #999;
        padding: 15px;
        box-sizing: border-box;
      }
    }
    .active {
      cursor: pointer;
      box-shadow: 0px 1px 10px 3px #dadada;
    }
    .activeSub {
      display: block;
    }
  }
  .placeholder-item {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    height: 0;
  }
  .partnerListOne:hover {
    cursor: pointer;
    box-shadow: 0px 0px 10px 3px #dadada;
  }
  .listOne_img:hover {
    img {
      transform: scale(1.1);
    }
  }
}
@media screen and(max-width: 998px) {
  .listOne {
    .partnerListOne { 
      width: 31%;
    }
  }
}
@media screen and(max-width: 768px) {
  .listOne {
    .partnerListOne { 
      width: 48.5%;
      max-width: unset;
      margin-bottom: 20px;
      &:nth-child(2n) {
        margin-right: 0;
      }
      .listOne_img {
        max-height: unset;
        max-width: unset;
        img {
          max-width: unset;
          max-height: unset;
        }
      }
      .listOne-Info { 
        flex-direction: column;
        .Info {
          flex-wrap: wrap;
          .place, .place_right{
            width: 100%;
            padding-left: 0px;
            padding-bottom: 15px;
          }
          .place_right {
            border-left: none;
          }
        }
      }
    }
  }
}
@media screen and(max-width: 380px) {
  .listOne {
    justify-content: center;
    .partnerListOne { 
      width: 100%;
      margin-right: 0;
      .listOne-Info { 
        .Info {
          flex-wrap: wrap;
          .place, .place_right{
            width: 100%;
            padding-left: 0px;
            padding-bottom: 15px;
          }
        }
      }
    }
  }
}
</style>

<style lang='less'>
@import '~@/styles/common/common.less';
.partnerList:hover {
  .button-default {
    color: #fff;
    border-color: @theme;
    background-color: @theme;
  }
}
</style>
