import { fetch } from '@/plugins/axios.js'
import lang from '@/utils/lang'
import site from '@/utils/site'

const apiUrl = {
  whereToBuy: `/partner/distribution_partner` // where to buy
}

// where to buy
export const getWhereToBuy = (params) => fetch(`${apiUrl.whereToBuy}/${lang}/${site}` + `?menu_id=${params.menu_id}&page=${params.page}&big_class=${params.big_class}&type_id=${params.type_id}`)

