<template>
  <div class="public-title">
    <div class="container">
      <div class="text-box">
        <h4
          v-if="dataInfo.title"
          class="title"
          :class="{
            large: size === 'large',
            left: site === 'left',
          }"
          v-html="dataInfo.title"
        ></h4>
        <h5 v-if="dataInfo.desc" class="desc" v-html="dataInfo.desc"></h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // title数据
    dataInfo: {
      type: Object,
      default: () => {}
    },
    // 文字大小
    size: {
      type: String,
      default: () => 'middle'
    },
    // 文字方向
    site: {
      type: String,
      default: () => 'center'
    }
  }
}
</script>

<style lang="less" scoped>
.public-title {
  .text-box {
    text-align: center;
    padding: 72px 0 24px 0;
    .title {
      font-size: 36px;
      font-weight: bold;
      color: #232931;
      line-height: 36px;
      &.large {
        font-size: 42px;
        line-height: 42px;
      }
      &.left {
        text-align: left;
      }
    }
    .desc {
      font-size: 14px;
      color: #606266;
      line-height: 19px;
      margin-top: 26px;
    }
  }
  @media screen and (max-width: 768px) {
    .text-box {
      padding: 40px 0 16px 0;
      .title {
        font-size: 24px;
        line-height: 24px;
      }
      .desc {
        margin-top: 16px;
      }
    }
  }
}
</style>
