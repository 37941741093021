<template>
  <div class="where-to-buy">
    <public-banner :banner-info="bannerInfo"/>
    <public-bread-crumb :bread-crumb="navigator" />
    <div class="title-box container">
      <h2 class="left-title">{{ title }}</h2>
      <a
          class="join-us-now"
          :href="LP.lang_join_us_now_url"
          target="_blank"
          :title="LP.lang_join_us_now"
          rel="nofollow noopener noreferrer"
      >{{ LP.lang_join_us_now }}</a>
    </div>
    <div class="pressReleaseTab">
      <div class="tabs container">
        <div>
          <div
            class="tab_item"
            v-for="(item, index) in tabsInfo"
            :key="index"
            @click="handleClickTab(item, index)"
            :class="{ 'on': tab_active_id===item.id}"
          >{{item.tab_label}}</div>
        </div>
		  </div>
	  </div>
    <div class="container">
      <el-select class="menu-select menu-select-m" v-model="menu_active_id" :placeholder="LP.lang_please_select" @change="menuChange()">
        <el-option
          v-for="(item, index) in menuList"
          :key="index"
          :label="item.menu_name"
          :value="item.menu_id">
        </el-option>
      </el-select>
      <div class="new-select menu-select-pc">
        <div class="select" v-for="(item, index) in menuList" :key="index" @click="handleClickMenu(item)" :class="{'on': menu_active_id === item.menu_id}">
          <span class="tab_span">{{item.menu_name}}</span>
        </div>
      </div>
      <list :dataInfo="partnerInfo" :name="menu_active_id"></list>
      <div class="pagination clearfix" v-show="total > perpage">
        <div class="pull-right">
          <el-pagination 
            layout="prev, pager, next"
            :current-page="pageIndex"
            :page-size="perpage"
            :total="total"
            @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicBanner from '@/components/public/PublicBanner.vue'
import PublicBreadCrumb from '@/components/public/PublicBreadCrumb.vue'
import PublicTitle from '@/components/public/PublicTitle.vue'
import list from '@/components/partner/list.vue'
import { mapState } from 'vuex'
import { getWhereToBuy } from '@/api/partner'

export default {
  components: {
    PublicBanner,
    PublicBreadCrumb,
    PublicTitle,
    list
  },
  data() {
    return {
      bannerInfo: {
        height: 420
      },
      title: ' ',
      aboutData: {},
      partnerData: {},
      honorTitle: {
        title: this.LP.lang_honors_awards
      },
      tabsInfo: [
        {
          id: 1,
          tab_label: this.LP.lang_distributors,
          tab_value: 'MONITOR'
        },
        {
          id: 2,
          tab_label: this.LP.lang_dealer_partners,
          tab_value: 'LED'
        },{
          id: 3,
          tab_label: this.LP.lang_digital_signage,
          tab_value: 'DIGITAL_SIGNAGE'
        },{
          id: 4,
          tab_label: this.LP.lang_ifp,
          tab_value: 'IFP'
        },
      ],
      cur_id_index: 0,
      partnerInfo: [],
      menuList: [],
      pageIndex: 1, // 列表当前页
      perpage: 10, // 列表分页条数
      total: 0, // 列表总条数
      menu_active_id: 'all',
      tab_active_value: 'MONITOR',
      tab_active_id: 1,
      scrollTop: 0,
      loading: false
    }
  },
  watch: {
    $route(to, from) {
      // 监听路由是否变化
      if (!to.query.id) {
        this.menu_active_id = 'all';
      }
      if (!to.query.big_class) {
        this.tab_active_id = this.tabsInfo[0].id;
        this.tab_active_value = this.tabsInfo[0].tab_value;
      }
      if (to.query !== from.query) {
        this.$store.dispatch('common/getNavigator', { type: 'partner', id: this.$route.params.id, level: 2 })
        this.pageIndex = 1;
        this.getData();
        this.$nextTick(()=> {
          document.documentElement.scrollTop = this.scrollTop;
        })
      }
    },
    navigator(val) {
      if (val && val.length > 0) {
        this.title = val[val.length - 1].nav_name || '';
      }
    }
  },
  computed: {
    ...mapState({
      headerBanner: state => state.common.banner,
      navigator: state => state.common.navigator
    })
  },
  created() {
    if (this.$route.query.big_class) {
      this.tab_active_value = this.$route.query.big_class;
      this.tab_active_id = this.tabsInfo.filter(ev=> { return ev.tab_value === this.tab_active_value})[0].id;
    }
    this.$store.commit('header/SET_WHITE_HEADER', false)
    this.$store.dispatch('common/getNavigator', { type: 'partner', id: this.$route.params.id, level: 2 })
    this.getData()
    this.getBanner()
  },
  methods: {
    // 获取banner
    async getBanner() {
      if (this.$route.fullPath.includes('business')) {
        await this.$store.dispatch('common/getBanner', { banner_id: 402 })
        this.bannerInfo = {...this.bannerInfo,...this.headerBanner[0]}
      } else {
        await this.$store.dispatch('common/getBanner', { banner_id: 402 })
        this.bannerInfo = {...this.bannerInfo,...this.headerBanner[0]}
      }
    },
    // 获取数据
    getData() {
      const params = {
        menu_id: this.$route.query.id || this.menu_active_id,
        page: this.pageIndex || 1,
        big_class: this.$route.query.big_class || this.tab_active_value,
        type_id: this.$route.params.id || ""
      }
      const all = [
        {
          "menu_id":"all",
          "menu_name": this.LP.lang_all,
          "menu_image":""
        }
      ]
      getWhereToBuy(params).then(({ data }) => {
        // console.log('data', data)
        this.total = data.count || 0;
        this.perpage = data.perpage || 10;
        this.menuList = [
          ...all,
          ...data.menuList
        ];
        this.partnerInfo = data.partnerInfo || [];
        if (data.seo) {
           this.$store.commit('common/SET_MATE', data.seo);
        }
        if (this.$route.query.id) {
          this.menu_active_id = this.$route.query.id;
        }
      })
    },
    handleClickTab(item) {
      this.tab_active_id = item.id;
      this.tab_active_value = item.tab_value;
      this.menu_active_id = 'all';
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.$router.push({
        query: {
          id: this.menu_active_id,
          big_class: this.tab_active_value.replace(/\s*/g,"")
        }
      })
    },
    handleClickMenu(item) {
      this.menu_active_id = item.menu_id;
      this.menuChange();
    },
    menuChange() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.$router.push({
        query: {
          id: this.menu_active_id,
          big_class: this.tab_active_value.replace(/\s*/g,"")
        }
      })
    },
    // 切换分页
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getData();
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
/deep/ .public-bread-crumb {
  background: #f5f5f5;
}
.where-to-buy {
  background: #f5f5f5;
  padding-bottom: 100px;
  .title-box {
    display: flex;
    align-items: center;
    padding: 8px 0 16px 0;
    .left-title {
      font-size: 32px;
      line-height: 38px;
    }
    .join-us-now {
      display: none;
      color: #fff;
      padding: 10px 16px 12px;
      font-size: 24px;
      background: #0f60af;
      margin-left: auto;
      cursor: pointer;
    }
  }
  .pressReleaseTab {
    background-color: #fff;
    width: 100%;
    .tabs {
      margin-bottom: 30px;
      & > div {
        position: relative;
      }

      .tab_item {
        min-width: 175px;
        height: 90px;
        line-height: 90px;
        font-size: 20px;
        padding: 0 16px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        position: relative;
        &:after {
          content: '';
          display: block;
          width: 0;
          height: 2px;
          background-color: #0e5fac;
          position: absolute;
          bottom: 0;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          -webkit-transition: all 0.5s;
          transition: all 0.5s;
        }
        &:hover {
          color: #0e5fac;
          &:after {
            width: 100%;
          }
        }
        &.on {
          color: @theme;
          &:after {
            width: 100%;
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      .tabs {
        .tab_item {
          // width: 4rem;
          // height: 1.2rem;
          // line-height: 1.2rem;
          // font-size: 0.32rem;
          min-width: 140px;
          height:  48px;
          line-height: 48px;
          font-size: 16px;
        }
      }
    }
  }
  .new-select{
    .select {
      display: inline-block;
      border: 1px solid @theme;
      border-radius: 20px;
      box-shadow: 0px 3px 13px 0px rgba(14, 95, 172, 0.1);
      padding: 5px 30px;
      margin: 0 20px 30px 0;
      color: @theme;
      cursor: pointer;
      font-weight: bold;
      font-size: 14px;
      line-height: initial;
    }
    .on {
      &:after {
        width: 100%;
      }
        color: #fff;
        background-color: @theme;
    }
  }
  .menu-select-pc {
    display: block;
  }
  .menu-select-m {
    margin-bottom: 20px;
    display: none;
  }
  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 768px) {
    .menu-select-pc {
      display: none;
    }
    .menu-select-m {
      display: block;
    }
    .title-box {
      flex-direction: column;
      align-items: unset;
      .left-title {
        padding-bottom: 16px;
        font-size: 20px;
        line-height: 24px;
      }
      .join-us-now {
        font-size: 14px;
      }
    }
  }
}
</style>
